import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { isEmpty } from 'lodash';
import { getOgImage } from "../../utils/functions";
// import "./style.scss"
import Group from '../../components/faq/group'
import More from '../../components/faq/more'

import { v4 as uuid } from 'uuid';

const FaqPage = (props) => {
  const {
    pageContext: {
      title, seo, uri, content
    }
  } = props;
  const { code: locale } = props.pageContext.wpmlTranslated.current[0];
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2);
  const faq = props.pageContext.acfFaq;
  const { moreQuestions } = faq;
  return (
    <Layout lang={lang} page="faq" translated={translated}>
      {
        !isEmpty(props.pageContext) ? (
          <>
            <SEO
              title={title}
translated={translated}
              seoData={seo}
              uri={uri}
              lang={lang}
              page={"faq"}
              // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
              openGraphImage={getOgImage(seo)}
            />
            <div className="faq page">
              <section className="description">
                <div className="container">
                  <div className="page__hero">
                    <h1 className="heading heading--xl">
                      {title}
                    </h1>
                    <div className="content" dangerouslySetInnerHTML={{ __html: content }}></div>
                  </div>
                </div>
              </section>
              {faq.group.map((element) => (
                <Group key={uuid()} group={element} />
              ))}
              <More description={moreQuestions.description}
                heading={moreQuestions.heading}
                buttonContact={moreQuestions.buttonContact}
                buttonReadMore={moreQuestions.buttonReadMore} />
            </div>
          </>
        ) : (
          <div>Something went wrong</div>
        )
      }
    </Layout>
  )
};
export default FaqPage;

